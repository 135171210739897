@font-face {
  font-family: 'Ubuntu Bold';
  src: url('fonts/ubuntu/Ubuntu-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Ubuntu Regular';
  src: url('fonts/ubuntu/Ubuntu-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  src: url('fonts/gotham-rounded/GothamRounded_Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url('fonts/gotham-rounded/GothamRounded_Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}
/*//////////*/
@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('fonts/myriad/Myriad-Pro-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: url('fonts/myriad/Myriad-Pro-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('fonts/myriad/Myriad-Pro-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  src: url('fonts/myriad/Myriad-Pro-Semibold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Myriad Pro Italic';
  src: url('fonts/myriad/Myriad Pro Italic.ttf')  format('truetype'); /* Safari, Android, iOS */
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center; 
  align-items: center; 
}

.click-region-outer {
  /* wrapper component to fix layout issues with scrollbars breaking alignment of home image overlay */
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  overflow: hidden;
}

.click-region-inner {
  position: relative;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 177.777vh;
}

.click-region {
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.image-map__content {
  position: relative !important;
}

.click-region span {
  cursor: pointer;
  border: solid 2px rgba(0, 170, 231, 0.0);
  border-radius: 10%;
  /*box-shadow: 120px 80px 40px 20px #0ff;*/
}

.click-region span:hover {
  /*cursor: pointer;*/
  /*border: solid 5px blue;*/
  /*background-color: rgba(25,200,0,0.33);*/

  width: 0px;
  height: 0px;
  background: rgba(167, 255, 104, 0.0);
  border-radius: 10%;
  box-shadow: 0px 0px 10px 10px rgba(0, 170, 231, 0.66);
  /* in order: x offset, y offset, blur size, spread size, color */
  /* blur size and spread size are optional (they default to 0) */


}

.image-click-region:hover {
  box-shadow: 0px 0px 10px 10px rgba(0, 170, 231, 0.66);
}


.code-input input {
  text-align: center;
}
/*https://css-tricks.com/snippets/css/using-font-face/*/
.custom-text {
  /*font-family: 'Gotham Rounded Bold';*/
  font-size: 20px;
  line-height: 15px;
}
/*https://css-tricks.com/snippets/css/using-font-face/*/
.custom-text2 {
  /*font-family: 'Gotham Rounded Bold';*/
  font-size: 16px;
  /*line-height: 40px;*/
}


.custom-text-Myriad-Pro-Bold-Big_login {
  font-family: 'Myriad Pro Bold';
  font-size: 15px;
  line-height: 15px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Bold-Big {
  font-family: 'Myriad Pro Bold';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}


.custom-text-Myriad-Pro-Bold-MegaPrintHeadline {
  font-family: 'Myriad Pro Bold';
  font-size: 36px;
  line-height: 34px;
  color: #002D59;
}
.custom-text-Myriad-Pro-Bold-MediumPrintHeadline {
  font-family: 'Myriad Pro Bold';
  font-size: 22px;
  line-height: 20px;
  color: #002D59;
}
.custom-text-Myriad-Pro-Regular-MediumPrintHeadline {
  font-family: 'Myriad Pro Regular';
  font-size: 16px;
  line-height: 22px;
  color: #002D59;
}

custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmallest {
  font-family: 'Myriad Pro Regular';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller {
  font-family: 'Myriad Pro Regular';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller {
  font-family: 'Myriad Pro Bold';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller {
  font-family: 'Myriad Pro Regular';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmallerItalic {
  font-family: 'Myriad Pro Italic';
  font-size: 20px;
  line-height: 20px;
  color: #002D59;
}
.custom-text-Myriad-Pro-Bold-SmallPrintHeadline {
  font-family: 'Myriad Pro Bold';
  font-size: 16px;
  line-height: 8px;
  color: #002D59;
}
.custom-text-Myriad-Pro-Regular-SmallPrintBlack {
  font-family: 'Myriad Pro Regular';
  font-size: 12px;
  line-height: 14px;
  color: black;
}
.custom-text-Myriad-Pro-Regular-TinyPrintBlack {
  font-family: 'Myriad Pro Regular';
  font-size: 22px;
  line-height: 20px;
  color: black;
}
.custom-text-Myriad-Pro-Regular-SmallPrintBlackLink {
  font-family: 'Myriad Pro Regular';
  font-size: 12px;
  line-height: 14px;
  color: black;
}
.custom-text-Myriad-Pro-Regular-SmallPrintRed {
  font-family: 'Myriad Pro Regular';
  font-size: 12px;
  line-height: 14px;
  color: #CD1719;
}

.custom-text-Myriad-Pro-Regular-DialogBoxLinkText {
  font-family: 'Myriad Pro Regular';
  font-size: 36px;
  line-height: 36px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxLowerText {
  font-family: 'Myriad Pro Light';
  font-size: 24px;
  line-height: 24px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxLowerTextMedium {
  font-family: 'Myriad Pro Medium';
  font-size: 15px;
  line-height: 15px;
  color: #002D59;
}
.custom-text-Myriad-Pro-Bold-DialogBoxLowerTextLight {
  font-family: 'Myriad Pro Bold';
  font-size: 15px;
  line-height: 15px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight {
  font-family: 'Myriad Pro Regular';
  font-size: 15px;
  line-height: 15px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxLowerTextSmaller {
  font-family: 'Myriad Pro Light';
  font-size: 14px;
  line-height: 14px;
  color: #002D59;
}



.custom-text-Myriad-Pro-Light-LoginBoldBlack {
  font-family: 'Myriad Pro Bold';
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.custom-text-Myriad-Pro-Regular-ExternalHeadline {
  font-family: 'Myriad Pro Regular';
  font-size: 26px;
  line-height: 26px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Bold-DialogBoxPrescribeHeadline {
  font-family: 'Myriad Pro Bold';
  font-size: 18px;
  line-height: 18px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Bold-DialogBoxPrescribe {
  font-family: 'Myriad Pro Bold';
  font-size: 14px;
  line-height: 14px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxPrescribe {
  font-family: 'Myriad Pro Light';
  font-size: 13px;
  line-height: 13px;
  color: #002D59;
}

.custom-text-Myriad-Pro-Light-DialogBoxAdverseSmallBlack {
  font-family: 'Myriad Pro Regular';
  font-size: 9px;
  line-height: 9px;
  color: #000000;
}
.custom-text-Myriad-Pro-Light-DialogBoxAdverseMediumBlack {
  font-family: 'Myriad Pro Bold';
  font-size: 10px;
  line-height: 10px;
  color: #000000;
}
.custom-text-Myriad-Pro-Light-DialogBoxAdverseMediumBlackFooter {
  font-family: 'Myriad Pro Bold';
  font-size: 8px;
  line-height: 8px;
  color: #000000;
}
.custom-text-Myriad-Pro-Light-DialogBoxAdverseMediumRegBlack {
  font-family: 'Myriad Pro Regular';
  font-size: 10px;
  line-height: 8px;
  color: #000000;
}
.custom-text-Myriad-Pro-Light-ButtonHeadline {
  font-family: 'Myriad Pro Light';
  font-size: 4px;
  line-height: 4px;
  color: #ffffff;
}
custom-text-Myriad-Pro-Regular-MicroPrintBlack{
font-family: 'Myriad Pro Light';
font-size: 4px;
line-height: 4px;
color: #000000;
}
.custom-text-Myriad-Pro-Light-ButtonLower {
  font-family: 'Myriad Pro Light';
  font-size: 7px;
  line-height: 7px;
  color: #ffffff;
}